// Generated from icomoon
export enum HuesIcon {
    COG          = "&#xe900;",
    PLAY         = "&#xe901;", // play3
    PAUSE        = "&#xe902;", // pause2
    SHUFFLE      = "&#xe903;",
    CHAIN_BROKEN = "&#xe904;", // chain-broken, unlink
    CHAIN        = "&#xe905;", // chain, link
    LOCKED       = "&#xe906;",
    UNLOCKED     = "&#xe907;",
    MENU         = "&#xe908;",
    BACKWARD     = "&#xe909;", // backward2
    FORWARD      = "&#xe90a;", // forward3
    REWIND       = "&#xe90b;", // first
    COPY         = "&#xe90c;",
    EYE          = "&#xe90d;",
    EYE_CLOSED   = "&#xe90e;",
};
